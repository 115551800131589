<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0">Новый Устройства</p>
          <div>
            <el-button type="success" size="medium" plain>{{$t("message.save")}}</el-button>
            <el-button type="warning" size="medium" plain>{{$t("message.close")}}</el-button>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
          <el-row :gutter="20">
            <el-col :span="12">
              <div class="app-form__group mb-4">
                <span class="input--label d-block mb-2">{{$t("message.nameGiven")}}</span>
                <el-input
                  :placeholder="$t('message.nameGiven')"
                  v-model="input"
                  size="medium"
                ></el-input>
              </div>
              <div class="app-form__group mb-4">
                <span class="input--label d-block mb-2">  {{$t('message.serial_number')}}</span>
                <el-input
                  :placeholder="$t('message.serial_number')"
                  v-model="input"
                  size="medium"
                ></el-input>
              </div>
            </el-col>
            <!-- end col -->

            <el-col :span="12">
              <div class="app-form__group mb-4">
                <span class="input--label d-block mb-2">  {{$t('message.ip_address')}}</span>
                <el-input
                  :placeholder="$t('message.ip_address')"
                  v-model="input"
                  size="medium"
                ></el-input>
              </div>

              <div class="app-form__group mb-4">
                <span class="input--label d-block mb-2">{{$t('message.ip_address')}}</span>
                <el-select
                  class="w-100"
                  v-model="region"
                 :placeholder="$t('message.ip_address')"
                  size="medium"
                >
                  <el-option label="Zone one" value="shanghai"></el-option>
                  <el-option label="Zone two" value="beijing"></el-option>
                </el-select>
              </div>

              <div class="app-form__group mb-4">
                <span class="input--label d-block mb-2">Статус</span>
                <el-switch v-model="delivery"></el-switch>
              </div>
            </el-col>
            <!-- end col -->
          </el-row>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      input: "",
      options: [
        {
          value: "Option1",
          label: "Option1",
        },
        {
          value: "Option2",
          label: "Option2",
        },
        {
          value: "Option3",
          label: "Option3",
        },
        {
          value: "Option4",
          label: "Option4",
        },
        {
          value: "Option5",
          label: "Option5",
        },
      ],
      options2: [
        {
          value: "HTML",
          label: "HTML",
        },
        {
          value: "CSS",
          label: "CSS",
        },
        {
          value: "JavaScript",
          label: "JavaScript",
        },
      ],
      value: "",
      value2: [],
      value3: [],
    };
  },
  components: {},
  mounted() {},
};
</script>
